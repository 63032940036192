@import '../../../../scss/media';

.service_page_about {
   margin-top: clamp(6.25rem, 0rem + 6.25vw, 7.5rem);

   @include desktop {
      margin-top: clamp(3.125rem, -12.5rem + 20.833vw, 6.25rem);
   }

   @include tablet {
      margin-top: 50px;
   }

   .about_wrap {
      &__text {
         color: #848685;
         font-size: clamp(1.125rem, -0.75rem + 1.875vw, 1.5rem);
         font-style: normal;
         font-weight: 500;
         line-height: clamp(1.5rem, -0.375rem + 1.875vw, 1.875rem);

         @include desktop {
            font-size: clamp(0.875rem, -0.375rem + 1.667vw, 1.125rem);
            line-height: clamp(1.25rem, 0rem + 1.667vw, 1.5rem);
         }

         @include tablet {
            font-size: 14px;
            line-height: 20px;
         }

         @include mobile {
            margin-top: 24px;
         }
      }

      .about_list {
         display: flex;
         flex-wrap: wrap;
         row-gap: 40px;
         margin-top: clamp(6.25rem, -9.375rem + 15.625vw, 9.375rem);
         margin-left: -10px;
         margin-right: -10px;

         @include desktop {
            margin-top: clamp(2.5rem, -16.25rem + 25vw, 6.25rem);
         }

         @include tablet {
            margin-top: 40px;
            row-gap: 20px;
            margin-left: -5px;
            margin-right: -5px;
         }

         @include mobile {
            margin-top: 20px;
         }

         .list_col {
            width: auto;
            flex: 1 1 25%;
            padding: 0 10px;

            @include tablet {
               flex: 1 1 50%;
               padding: 0 5px;
            }

            @include mobile {
               flex: 1 1 100%;
            }
         }

         &__item {
            display: flex;
            flex-direction: column;
            row-gap: clamp(1.5rem, -5.063rem + 6.563vw, 2.813rem);

            @include desktop {
               row-gap: clamp(0.625rem, -3.75rem + 5.833vw, 1.5rem);
            }

            @include tablet {
               row-gap: 0px;
               border-top: 1px solid #848685;
               padding-top: 20px;
            }

            &-head {
               display: flex;
               align-items: center;
               column-gap: clamp(1.25rem, -0.625rem + 1.875vw, 1.625rem);

               @include desktop {
                  column-gap: clamp(0.625rem, -2.5rem + 4.167vw, 1.25rem);
               }

               @include tablet {
                  display: none;
               }

               .circle {
                  display: flex;
                  width: 100%;
                  max-width: clamp(1.375rem, -4.25rem + 5.625vw, 2.5rem);
                  border-radius: 50%;
                  overflow: hidden;
                  border: 3px solid #56DAB0;
                  aspect-ratio: 1/1;
                  height: auto;

                  @include desktop {
                     max-width: 22px;
                  }
               }

               .line {
                  display: flex;
                  flex: 1 1 auto;
                  height: 1px;
                  width: auto;
                  background: #848685;
               }
            }

            &-text {
               color: #FFF;
               font-size: clamp(1.125rem, -0.75rem + 1.875vw, 1.5rem);
               font-style: normal;
               font-weight: 500;
               line-height: clamp(1.5rem, -0.375rem + 1.875vw, 1.875rem);

               @include desktop {
                  font-size: clamp(0.875rem, -0.375rem + 1.667vw, 1.125rem);
                  font-weight: 400;
                  line-height: clamp(1.25rem, 0rem + 1.667vw, 1.5rem);
               }

               @include tablet {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
               }
            }
         }
      }

      .sw_page_about {
         overflow: visible;
         margin-top: clamp(5rem, -1.25rem + 6.25vw, 6.25rem);
         // 100px / 80px

         @include desktop {
            margin-top: clamp(1.563rem, -15.625rem + 22.917vw, 5rem);
            // 80px / 25px
         }

         @include tablet {
            margin-top: 25px;
         }

         .item {
            width: 100%;
            height: auto;
            aspect-ratio: 180/109;
            overflow: hidden;
            border-radius: 4px;

            @include tablet {
               aspect-ratio: 362/241;
            }

            @include mobile {
               max-width: 330px;
            }

            .image {
               width: 100%;
               height: 100%;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }
         }

         .sw_controls {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 30px;
            position: relative;

            @include desktop {
               margin-top: 20px;
            }

            @include tablet {
               margin-top: 13px;
            }

            @include mobile {
               display: none;
            }

            .swiper-pagination {
               display: flex;
               column-gap: 8px;
               margin: 0;
               padding: 0;
               position: absolute;
               left: 50%;
               transform: translateX(-50%);
               top: auto;
               bottom: auto;
               right: auto;
               width: auto;
               flex-wrap: wrap;
               padding: 0 100px;

               @include desktop {
                  column-gap: 6px;
               }

               &-bullet {
                  margin: 0;
                  padding: 0;
                  background: #848685;
                  opacity: 1;
                  width: 8px;
                  height: 8px;
                  transition: .3s;

                  @include desktop {
                     width: 6px;
                     height: 6px;
                  }

                  @include tablet {
                     width: 4px;
                     height: 4px;
                  }
               }

               &-bullet-active {
                  width: 20px;
                  height: 8px;
                  border-radius: 10px;

                  @include desktop {
                     width: 16px;
                     height: 6px;
                  }

                  @include tablet {
                     height: 4px;
                  }
               }
            }

            .swiper_btns {
               position: relative;
               display: flex;
               justify-content: flex-end;
               column-gap: 8px;

               &>div {
                  display: flex;
                  margin: 0;
                  padding: 0;
                  width: 32px;
                  height: 32px;
                  position: unset;

                  @include hover {
                     svg circle {
                        fill: #848685;
                     }

                     svg path {
                        fill: #fff;
                     }
                  }

                  @include desktop {
                     width: 24px;
                     height: 24px;
                  }

                  &::after {
                     display: none;
                  }

                  svg {
                     width: 100%;
                     height: 100%;
                  }
               }

               @include desktop {
                  column-gap: 6px;
               }

               @include tablet {
                  display: none;
               }
            }
         }
      }
   }
}