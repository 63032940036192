@import '../../../../scss/media';

.explore {
   background: #F8F7F4;
   padding-top: 20px;
   padding-bottom: 20px;

   @include tablet {
      padding-top: 50px;
      padding-bottom: 50px;
      background: #2C2928;
   }

   &__wrap {
      padding-top: 80px;

      @include tablet {
         padding-top: 0px;
      }
   }

   .col_head {
      display: none;

      @include tablet {
         display: flex;
         row-gap: 20px;
         flex-direction: column;
         margin-bottom: 0px;

         .title {
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            color: #fff;
         }

         .text {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            padding-right: 280px;

            @include mobile {
               padding-right: 0px;
            }
         }
      }

      @include mobile {
         row-gap: 12px;
         margin-bottom: 0px;
      }

   }

   &__wrap_l {
      display: flex;
      flex-direction: column;
      padding-bottom: 350px;

      @include tablet {
         position: unset;
         height: auto;
         max-height: unset;
         padding-bottom: 0px;
      }

      .head {
         display: flex;
         flex-direction: column;
         row-gap: 30px;
         width: 100%;
         max-width: calc(100% - 17.3%);

         @include tablet {
            max-width: 100%;
            row-gap: 20px;
            display: none;
         }

         @include mobile {
            display: flex;
            row-gap: 12px;
         }

         h2 {
            color: #2E2D2D;

            @include mobile {
               color: #fff;
            }
         }

         .text {
            color: #2E2D2D;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;

            @include desktop {
               font-size: 18px;
               line-height: 24px;
            }

            @include tablet {
               font-size: 14px;
               line-height: 20px;
            }

            @include mobile {
               color: #fff;
            }
         }
      }

      .list_wrap {
         position: relative;
         display: flex;

         .list_pagination {
            height: 100%;
            min-width: calc(8.333333333% + 20px);
            height: auto;
            padding-top: 100px;

            &__wrap {
               position: sticky;
               top: 100px;
               display: flex;
               flex-direction: column;
               row-gap: 10px;

               &-item {
                  width: 4px;
                  height: 20px;
                  background: #56DAB0;
                  color: transparent;
                  font-size: 1px;
                  border-radius: 8px;
                  transition: .4s;
               }
            }


         }
      }

      .list_accord {
         display: flex;
         flex-direction: column;
         row-gap: 350px;
         margin-top: 240px;
         margin-left: 40px;
         position: relative;

         @include desktop {
            margin-top: 180px;
         }

         @include tablet {
            margin: 0px;
         }

         .item {
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            @include desktop {
               row-gap: 20px;
            }

            &__title_block {
               display: flex;
               align-items: center;
               column-gap: 20px;

               @include hover {
                  .icon {
                     svg {
                        rect {
                           fill: #2E2D2D;
                        }
                     }
                  }
               }

               .title {
                  color: #2E2D2D;
                  font-size: 30px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 36px;
               }

               .icon {
                  display: flex;

                  svg {
                     rect {
                        transition: .4s;
                     }
                  }
               }
            }

            &__meta {
               display: flex;
               display: none;
               column-gap: 30px;

               .meta_item {
                  color: #848685;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
               }
            }

            &__text {
               color: #2E2D2D;
               font-size: 24px;
               font-style: normal;
               font-weight: 500;
               line-height: 30px;
               max-width: 680px;

               @include desktop {
                  font-size: 18px;
                  line-height: 24px;
                  max-width: 540px;
               }
            }

            &__author {
               display: flex;
               display: none;
               align-items: center;
               column-gap: 20px;
               margin-top: 10px;

               .author_avatar {
                  width: 100%;
                  max-width: 60px;
                  aspect-ratio: 1/1;
                  height: auto;
                  overflow: hidden;
                  border-radius: 50%;

                  img {
                     width: 100%;
                     height: 100%;
                     object-fit: cover;
                  }
               }

               .author_name {
                  width: 100%;
                  max-width: calc(100% - 80px);
                  color: #848685;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
               }
            }
         }
      }


   }

   &__wrap_r {
      display: flex;
      flex-direction: column;
      position: sticky;
      top: 70px;

      @include desktop {
         top: 60px;
      }

      @include tablet {
         position: unset;
      }


      .sw_explore {
         max-width: 100%;
         overflow: hidden;

         @include tablet {
            overflow: visible;
            margin-top: 52px;
            position: relative;
         }

         .swiper-pagination {
            display: none;

            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;

            @include tablet {
               display: flex;
               top: -36px;
               left: 0px;
               right: auto;
               bottom: auto;
            }
         }
      }

      .service_photos {
         position: relative;
         overflow: hidden;
         border-radius: 4px;
         aspect-ratio: 272/315;
         width: 100%;
         max-height: calc(100dvh - 90px);
         height: auto;

         @include desktop {
            aspect-ratio: 272/315;
         }

         @include tablet {
            aspect-ratio: unset;
         }

         .item_wrap {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
         }
      }

      .item_wrap {

         @include tablet {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            margin-top: 0px;
            background: #fff;
            padding: 24px 12px 12px 12px;
            max-width: 300px;
            justify-content: space-between;
            height: auto;
            border-radius: 4px;
         }

         .meta_slide {
            display: none;

            @include tablet {
               display: flex;
               flex-direction: column;
               row-gap: 16px;


               h3 {
                  color: #848685;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 24px;
               }

               .text {
                  color: #848685;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;

                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-line-clamp: 6;
                  -webkit-box-orient: vertical;
               }
            }
         }

         .item {
            position: relative;
            height: 100%;

            @include tablet {
               &::before {
                  content: '';
                  display: block;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background: linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 62.25%);
                  z-index: 1;
               }

               &::after {
                  content: '';
                  display: block;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 62.25%);
                  z-index: 1;
               }
            }

            @include tablet {
               aspect-ratio: 361/449;
               width: 100%;
               height: auto;
               overflow: hidden;

               border-radius: 4px;
               aspect-ratio: 276/343;
               overflow: hidden;
               width: 100%;
            }

            .head {
               @include tablet {
                  display: flex;
                  display: none;
                  flex-direction: column;
                  row-gap: 3px;
                  position: absolute;
                  top: 20px;
                  left: 20px;
                  z-index: 3;
               }

               .item_head {
                  @include tablet {
                     color: #FFF;
                     font-family: Gilroy;
                     font-size: 14px;
                     font-style: normal;
                     font-weight: 500;
                     line-height: 20px;
                  }
               }
            }

            .people {
               @include tablet {
                  display: flex;
                  display: none;
                  align-items: center;
                  column-gap: 16px;

                  position: absolute;
                  bottom: 20px;
                  left: 20px;
                  z-index: 3;
               }

               .avatar {
                  @include tablet {
                     width: 100%;
                     max-width: 40px;
                     height: auto;
                     border-radius: 50%;
                     overflow: hidden;

                     img {
                        height: auto;
                     }
                  }
               }

               .name {
                  @include tablet {
                     display: flex;

                     color: #FFF;
                     font-family: Gilroy;
                     font-size: 14px;
                     font-style: normal;
                     font-weight: 500;
                     line-height: 20px;
                  }
               }
            }

            .image {
               width: 100%;
               height: 100%;
               position: relative;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }
         }
      }

   }

   .left {
      @include mobile {
         display: none;
      }
   }
}